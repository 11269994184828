import { QueryClientProvider } from '@tanstack/react-query'
import { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { DynamicCreateElementContainer } from 'redyc'
import { LoadingPage } from './components/ui/loading'
import { Toaster } from './components/ui/sonner'
import { queryClient } from './lib/tanstack-query'
import { setVisibleHeight } from './lib/visible-height'
import { router } from './router'

setVisibleHeight()

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <QueryClientProvider client={queryClient}>
        <DynamicCreateElementContainer>
          <RouterProvider router={router} fallbackElement={<LoadingPage />} />
          <Toaster richColors position="top-right" />
        </DynamicCreateElementContainer>
      </QueryClientProvider>
    </Suspense>
  )
}

export default App
