import { QueryClient, type UseQueryOptions } from '@tanstack/react-query'

export const queryClient = new QueryClient()

export const immutableQuery = {
  staleTime: Number.POSITIVE_INFINITY, // Data will never be considered stale
  gcTime: Number.POSITIVE_INFINITY, // Cache will persist indefinitely
  refetchOnWindowFocus: false, // Do not refetch on window focus
  refetchOnReconnect: false, // Do not refetch on reconnect
} as const satisfies Omit<UseQueryOptions, 'queryKey'>
